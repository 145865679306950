/*
#***********************************************
#
#      Filename: src/api/exceptionOrder/index.js
#
#        Author: xiaowang - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 财务报表相关api
#        Create: 2023-07-20 10:10:36
# Last Modified: 2023-07-26 13:59:59
#***********************************************
*/
import request from '@/utils/request'

// 主列表
export function list(data) {
  return request({
    url: '/pmTimeLimit/list',
    method: 'get',
    params: data
  })
}

// 主列表删除
export function deleteList(id) {
  return request({
    url: `/pmTimeLimit/delete/${id}`,
    method: 'post'
  })
}

// 主列表批量删除
export function allDelList(data) {
  return request({
    url: '/pmTimeLimit/batchdel',
    method: 'post',
    params: data
  })
}

// 新增限时免费名单
export function add(data) {
  return request({
    url: '/pmTimeLimit/add',
    method: 'post',
    params: data
  })
}

// 编辑限时免费名单
export function edit(data) {
  return request({
    url: '/pmTimeLimit/update',
    method: 'post',
    params: data
  })
}

// 导出
export function exportData(data) {
  return request({
    url: '/pmTimeLimit/export',
    method: 'get',
    params: data
  })
}
