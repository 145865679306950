<!--
#***********************************************
#
#      Filename: src/views/List/WhiteList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 限时免费名单
#        Create: 2023-07-11 13:36:50
# Last Modified: 2023-07-11 13:36:50
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :import-actions="importActions"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增限时免费名单')"
      @refresh="handleRefresh"
      @export="handleExport"
      @download="handleDownload"
      @import-success="handleImportSuccess"
      @batch-delete="handleBatchDelete('批量删除限时免费名单', handleBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe size="mini" border @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-for="(item, index) in tableHeadFields"
        v-show="item.isShow"
        :key="index"
        :label="item.label"
        :width="item.width"
        :align="item.align"
        :prop="item.prop">
        <template slot-scope="scope">
          <template v-if="index == 3">
            {{ scope.row.startTime ? parseTime(scope.row.startTime) : '--' }}
          </template>
          <template v-else-if="index == 4">
            {{ parseTime(scope.row.endTime) }}
          </template>
          <template v-else-if="index == 5">
            {{ scope.row.dateline ? parseTime(scope.row.dateline) : '--' }}
          </template>
          <template v-else-if="index == 6">
            {{ formOperName(scope.row.operId) }}
          </template>
          <template v-else>
            {{ scope.row[item.prop] ? scope.row[item.prop] : '--' }}
          </template>
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '修改限时免费名单', beforeEdit)">
              编辑
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.id, '删除限时免费名单', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="业主姓名:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入业主姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话号码:" prop="phone">
              <el-input v-model="actionDataItem.phone" placeholder="请输入业主电话号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="startTime">
              <el-date-picker
                v-model="actionDataItem.startTime"
                type="datetime"
                placeholder="请选择开始时间"
                default-time="12:00:00"
                style="width: 100%"
                value-format="timestamp"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间:" prop="endTime">
              <el-date-picker
                v-model="actionDataItem.endTime"
                type="datetime"
                placeholder="请选择结束时间"
                default-time="12:00:00"
                style="width: 100%"
                value-format="timestamp"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="车牌号:" prop="carNumber">
              <el-input v-model="actionDataItem.carNumber" placeholder="请输入车牌号码" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="actionDataItem.remark" type="textarea" placeholder="请输入备注信息" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { isCarNumber } from '@/utils/validate'
  import { list, deleteList, add, edit, allDelList, exportData } from '@/api/freeOrder'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'FreeOrderList',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      // 车牌号校验规则
      const validatorCph = (rule, value, callback) => {
        if (isCarNumber(value)) {
          callback()
        } else {
          return callback(new Error('车牌号不正确'))
        }
      }

      return {
        // startTime: '',
        // endTime: '',
        // carNumber: '',
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          phone: '',
          carNumber: '',
          remark: '',
          startTime: '',
          endTime: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          phone: [{ pattern: /^1[3|5|7|8|9]\d{9}$/, message: '请输入正确的号码格式', trigger: 'change' }],
          carNumber: [{ required: true, message: '请输入正确的车牌号码', trigger: 'blur', validator: validatorCph }]
        },
        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: true,
            tableHeadToggleShow: true,
            searchPlaceholder: '请输入车牌号'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: false,
            importDataShow: false,
            downloadTemplateShow: false,
            more: [
              {
                label: '批量删除',
                permission: 'delete',
                emitEvent: 'batch-delete'
              }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'carNumber',
                placeholder: '请输入车牌号'
              }
            ]
          }
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '车主姓名',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '电话号码',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '车牌号',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'carNumber'
          },
          {
            label: '开始时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'startTime'
          },
          {
            label: '结束时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'endTime'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'dateline'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'operId'
          },
          {
            label: '备注',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'remark'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'manager:pmTimeLimit:add',
          info: 'limittime-freelist:info:info',
          delete: 'manager:pmTimeLimit:del',
          update: 'manager:pmTimeLimit:edit'
        }
      }
    },
    computed: {
      importActions() {
        return process.env.VUE_APP_BASE_API + '/pmTimeLimit/uploadExcel?pId=' + this.pid + '&operId=' + this.uid
      }
    },

    mounted() {
      this.getList()
    },

    methods: {
      // 主列表数据
      getList() {
        if (this.pid) {
          // 组装查询条件
          const queryData = this.filterData
          queryData.pId = this.pid
          queryData.current = this.currentPage
          queryData.size = this.pageSize
          queryData.carNumber = this.filterData.searchStr

          list(queryData)
            .then((res) => {
              this.dataList = res.data.records
              this.total = res.data.total
            })
            .catch((err) => {
              console.error('车位列表查询出错', err)
            })
        }
      },

      // 主列表单条删除
      handleDel(id) {
        deleteList(id)
          .then((res) => {
            if (res) {
              this.noticeSuccess('删除成功')
              this.handleRefresh()
            } else {
              this.noticeError(res.message)
            }
          })
          .catch((err) => {
            this.msgError(err.message)
          })
      },

      // 刷新
      handleRefresh() {
        this.getList()
      },

      // 编辑前执行
      beforeEdit(data) {
        console.log('action1', data)
        console.log('action2', JSON.stringify(data))
        this.actionDataItem = Object.assign({}, data)
        console.log('actionDataItem3', this.actionDataItem)
        this.actionDataItem.startTime = this.actionDataItem.startTime * 1000
        this.actionDataItem.endTime = this.actionDataItem.endTime * 1000
        console.log('action4', this.actionDataItem)
      },

      // 提交信息
      submitForm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (this.modalObject.currentAction === 'edit') {
              this.actionDataItem.pId = this.pid
              this.actionDataItem.operId = this.uid
              if (this.actionDataItem.endTime < this.actionDataItem.startTime) {
                this.noticeError('结束时间不能小于开始时间')
                return
              }
              if (this.actionDataItem.endTime < new Date()) {
                this.noticeError('结束时间不能小于当前时间')
                return
              }
              if (this.actionDataItem.startTime === '') {
                this.noticeError('开始时间不能为空')
                return
              }
              if (this.actionDataItem.endTime === '') {
                this.noticeError('结束时间不能为空')
                return
              }
              const startTime = this.actionDataItem.startTime / 1000
              const endTime = this.actionDataItem.endTime / 1000
              const actionSubmitData = JSON.parse(JSON.stringify(this.actionDataItem))
              actionSubmitData.startTime = startTime
              actionSubmitData.endTime = endTime
              edit(actionSubmitData)
                .then((res) => {
                  if (res.result) {
                    this.modalObject.status = false
                    this.handleRefresh()
                    this.noticeSuccess('操作成功')
                  } else {
                    this.modalObject.status = false
                    this.noticeError(res.message)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            } else {
              this.actionDataItem.pId = this.pid
              this.actionDataItem.operId = this.uid
              if (this.actionDataItem.endTime < this.actionDataItem.startTime) {
                this.noticeError('结束时间不能小于开始时间')
                return
              }
              if (this.actionDataItem.endTime < new Date()) {
                this.noticeError('结束时间不能小于当前时间')
                return
              }
              if (this.actionDataItem.startTime === '') {
                this.noticeError('开始时间不能为空')
                return
              }
              if (this.actionDataItem.endTime === '') {
                this.noticeError('结束时间不能为空')
                return
              }
              const startTime = this.actionDataItem.startTime / 1000
              const endTime = this.actionDataItem.endTime / 1000
              const actionSubmitData = JSON.parse(JSON.stringify(this.actionDataItem))
              actionSubmitData.startTime = startTime
              actionSubmitData.endTime = endTime
              add(actionSubmitData)
                .then((res) => {
                  console.log('res', res)
                  if (res.result) {
                    this.modalObject.status = false
                    this.noticeSuccess('操作成功')
                    this.handleRefresh()
                  } else {
                    this.modalObject.status = false
                    this.noticeError(res.message)
                  }
                })
                .catch((err) => {
                  console.log(err)
                })
            }
          }
        })
      },

      // 批量删除
      handleBatchDel(ids) {
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.ids = idArr.join(',')
        allDelList(postData)
          .then((res) => {
            console.log(res)
            this.noticeSuccess('批量删除成功')
            this.handleRefresh()
          })
          .catch((err) => {
            this.noticeError(err.message)
          })
      },

      // 导出
      handleExport() {
        const _this = this
        this.$confirm('是否导出限时免费名单列表？', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.pId = this.pid
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            exportData(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'dateline' || j === 'startTime' || j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '限时免费名单列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },
      /**
       * 下载模板
       */
      handleDownload() {
        window.open('http://matrixoss.oss-cn-zhangjiakou.aliyuncs.com/OPO/data/template/timeLimit.xls')
      },
      /**
       * 上传成功
       */
      handleImportSuccess() {
        this.noticeSuccess('上传成功')
        this.handleRefresh()
      }
    }
  }
</script>
<style scoped lang="scss">
  .gz-dynamtic-input-wrapper {
    display: grid;
    grid-column-gap: 20px;
  }
</style>
